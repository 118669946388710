import type { DataObject } from 'o365-dataobject';
import FilterItem from './FilterItem.ts';
import { createInitialObject, filterItemsToString, prettifyFilterItems, filterStringToFilterItems } from './helpers.filterUtils.ts';
import { dataUtils as utilsData } from 'o365-utils';
import UrlFilter from './UrlFilter.ts';
import {getViewFiltersDO,deleteFilterPerson, hideSharedFilter, unHideSharedFilter} from './store.ts';
import { localStorageHelper } from 'o365-modules';
import { ExistsObject } from './helpers.interfaces.ts';
import {itemFromFunctionItem} from './helpers.FunctionConverters.ts'
import { combinedExpressions } from './helpers.dateExpressions.ts';

interface SavedFilter {
    PrimKey: string,
    FilterCriteria: string,
    FilterName: string
}

interface DevexObject {
    items: Array<FilterItem>

}

interface IFieldFilter {
    distinct?: {
        columns?: string,
        column?: string
    },
    name?: string,

}

export default class FilterObject {
    activeFilterObject: DevexObject | null = null;
    urlFiltering = false;
    dataObject: DataObject;
    private _urlFilter: UrlFilter|null = null;
    private _fieldFilters: IFieldFilter[];
    /** Field filters set by developer/grid stored here when saved filter is applied */
    private _previousFieldFilters: IFieldFilter[] | null = null;
    private _filterString: string|null  = null;
    //private _filterStringWithoutSearch: string|null  = null;
    private _prettyString: string | null = null;
    private _activeFilter: SavedFilter | null = null;
    private filterItems: Record<string, any> = {};
    private _filtersListDO: DataObject | null = null;
    private _preventDistinctColumns: string[] = ['Description'];
    private _persistentFilterId?: string;
    private _activeSearchFncItem: any|null = null;
    columns: Array<any>;
    name: string|null = null;
    filterObject: DevexObject | null = null;
    distinctOptions: any|null = null;
    columnDistinctOptions: Map<string, object> = new Map();
    columnExistsOptions: Map<string, ExistsObject> = new Map();
    private _searchFncOptions:any = null;
    persistentFilterEnabled = false;
    /** WHen true all field filters of this object will not use the autocomplete component */
    disableAutoComplete = false;
    private _dateExpressions: Array<string> = [];
    get persistentFilterId() { return this._persistentFilterId; };
    set persistentFilterId(pValue) { this._persistentFilterId = pValue; this.initializePersistentFilter(); }
    get _urlFilterKey() {
        return "f_" + this.dataObject.id;
    }

    get fieldFilters() {

        let vReturn:Array<any> = [];
        if(this.activeSearchFncItem){
            vReturn.push(this.activeSearchFncItem.column)
        }
        vReturn = vReturn.concat(this._fieldFilters?.map(x => x.name));
        Object.values(this.filterItems).filter(x => x.selectedValue).forEach(item => {
            if (vReturn.indexOf(item.column) === -1) {
                vReturn.push(item.column);
            }
        })
        return vReturn;
    }

    get filterString() {
        return this._filterString === '' ? null : this._filterString;
    }

    get filtersListDO(){
        if(!this._filtersListDO){
            this._filtersListDO = getViewFiltersDO(this.viewName);
            this._filtersListDO.softDeleteItem = this.softDeleteFilter;
            this._filtersListDO.enableContextFilter(null);
        }

        return this._filtersListDO;

    }

    get viewName(){
        return this.dataObject.viewName;
    }

    get prettyString() {
        if(this.activeSearchFncItem && this.activeSearchFncItem.isSet){
            if(this._prettyString){
                return this._prettyString + " AND " + this.activeSearchFncItem.prettyString;
            }
            return this.activeSearchFncItem.prettyString;
        }
        return this._prettyString;
    }

    get combinedFilterString(){
         if(this.activeSearchFncItem && this.activeSearchFncItem.isSet){
            if(this._filterString){
                return this._filterString + " AND " + this.activeSearchFncItem.sarchStringForSave;
            }
            return this.activeSearchFncItem.sarchStringForSave;
        }
        return this._filterString;
    }

    get appliedFilterString() {
        return this.dataObject.recordSource.filterString;
    }

    get searchAppliedSearchFunction(){
        return this.dataObject.recordSource.searchFunction;
    }
    get searchAppliedSearchFunctionString(){
        return this.dataObject.recordSource.searchString?this.dataObject.recordSource.searchString:null;
    }

    get activeSearchFncItem(){
        return this._activeSearchFncItem;
    }
    get searchFunctionApplied(){
        return this._activeSearchFncItem && this._activeSearchFncItem.isSet && this._activeSearchFncItem.searchString === this.searchAppliedSearchFunctionString;
    }

    get searchFunctionHasChanges(){
        return this._activeSearchFncItem && this._activeSearchFncItem.searchString !== this.searchAppliedSearchFunctionString;
    }

    get hasChanges() {
        //return this.appliedFilterString !== this.filterString;
        return this.appliedFilterString !== this.filterString || this.searchFunctionHasChanges;
    }
    get activeFilterHasChanges(){
        if(!this._activeFilter) return false;
        return this._activeFilter.FilterCriteria !== this.combinedFilterString;
    }

    get activeFilter() {
        return this._activeFilter;
    }
    set activeFilter(pFilter: SavedFilter | null) {

        //on delete filter if its active need to reset
        if (pFilter) {
            this.setIndexForFiltersList(pFilter.PrimKey);

        }
        this._activeFilter = pFilter;
    }

    get activeItems() {
        if(this.activeSearchFncItem && this.activeSearchFncItem.isSet){
            return Object.values(this.filterItems).filter(x => x.selectedValue != null).concat([this.activeSearchFncItem])
        }
        return Object.values(this.filterItems).filter(x => x.selectedValue != null);
    }


    get dateExpressions() {

        if (this._dateExpressions.length == 0) {

            let vDateExpr:any = combinedExpressions;
            this._dateExpressions = Object.keys(vDateExpr).map(key => { vDateExpr[key]["id"] = key; vDateExpr[key]["key"] = key + "_" + Math.random().toString(); return vDateExpr[key] });
        }

        return this._dateExpressions;
    }

    get hasAppliedItems() {
        return Object.values(this.filterItems).some(item => item.applied);
    }


    constructor(pOptions: any) {
        this.dataObject = pOptions.dataObject;
        this.columns = pOptions.columns;
        this._fieldFilters = pOptions.fieldFilters ?? [];
    }

    /**
     * pColumns array
     */
    updateColumns(pColumns: Array<any>) {
        pColumns.forEach(pColumn => {
            this.updateColumn(pColumn);
        })
    }

    setIndexForFiltersList = (pKey: string) => {
        this._filtersListDO?.setCurrentIndexByPrimKey(pKey);
    }

    /**
     * pColumn object
     */
    updateColumn(pColumn: any) {
        let vField = this.columns.find(col => col.name === (pColumn.filterField ?? pColumn.name));
        if (vField) {
            //vColumn = Object.assign(pColumn,vColumn);
            if (pColumn.filter) vField.filter = pColumn.filter;
            if (pColumn.filterDropdown) vField.filterDropdown = pColumn.filterDropdown;
            if (pColumn.filterParams) vField.filterParams = pColumn.filterParams;
            if (pColumn.format) vField.format = pColumn.format;
            if (pColumn.distinct) vField.distinct = pColumn.distinct;
            if (pColumn.distinctHandler) vField.distinctHandler = pColumn.distinctHandler;

            if (!vField.filterDropdown) vField.filterDropdown = "OFilterDropdown";
        } else {
            if (!pColumn.name.startsWith("o365_")) {
                this.columns.push(pColumn);
            }

        }

        if (pColumn.disableDistinct) {
            this._preventDistinctColumns.push(pColumn.filterField ?? pColumn.name);
        }

        if (this._urlFilter) this._urlFilter.columns = this.columns;
    }

    /**need to get oobject */
    setFieldFilters(pFieldFilters: Array<string | { name: string, distinct?: string }>, pClear = false, pSavePrevious = false) {
        if (pSavePrevious && !this._previousFieldFilters && this._fieldFilters) {
            this._previousFieldFilters = this._fieldFilters;
        }
        if (!this._fieldFilters || pClear) {
            this._fieldFilters = [];
        }
        pFieldFilters.forEach(filter => {
            let item: IFieldFilter = {};
            if (typeof filter === 'object') {
                // @ts-ignore
                item = filter;
                if (filter.distinct) {
                    item.distinct = {
                        columns: filter.distinct,
                        column: filter.name
                    }
                }
            } else {
                item.name = filter;
            }


            this.updateColumn(item);
            this._fieldFilters.push(item);
        });
    }

    /**
     * Remove field from field filters list
     */
    removeFieldFilter(name: string) {
        const index = this._fieldFilters.findIndex(x => x.name === name);
        if (index === -1) {
            console.warn(`${this.dataObject.id}: ${name} is not defined in field filters`);
        } else {
            this._fieldFilters.splice(index, 1);
        }
    }

    /** Clears filter item and removes it from field filter */
    removeItem(name: string) {
        if (this.filterItems[name]) {
            this.removeFieldFilter(name);
            this.filterItems[name].clear();
        }
    }
    softDeleteFilter = async function(item:any){
        if(item.SharedPerson){
            await deleteFilterPerson.execute({
                ID:item.ID
            });
            this.remove(item.index);

        }

        if(item.SharedOrgUnit){
            await hideSharedFilter.execute({
                ID:item.ID
            });
              item.hidden = true;
            this.storage.updateItem(item.index,{Hidden:true},true);
        }
 

     
    }

    unhideSharedFilter = async function(item:any){
        await unHideSharedFilter.execute({
            ID:item.ID
        })
        item.hidden = false;
        this.filtersListDO.storage.updateItem(item.index,{Hidden:false},true);
    }

    setFilterString(filterString: string|null){
        this.dataObject.recordSource.filterString = filterString;
        this._setSearchFunctionProps();
    }

    getItem(pColumnName: string): FilterItem | undefined {
        let column = this.columns.find(x => x.name === pColumnName);
        if (!column) {
            console.warn(pColumnName, "Not found ")
        
            column = {
                column: pColumnName,
                name: pColumnName,
                type: 'string',
                columns: this.columns
            }
            this.columns.push(column);
        }
        if(!column.filterDropdown){
              column.filterDropdown = "OFilterDropdown";
        }
        if (!this.filterItems.hasOwnProperty(pColumnName)) {
            //  if(column.type == "string" && column["maxLength"] && column["maxLength"] <= 1028 && !column.distinct && vPreventDistinctColumns.indexOf(column.name) === -1 && column["maxLength"] !== -1){
            if (column.type == "string" && column["maxLength"] && !column.distinct && this._preventDistinctColumns.indexOf(column.name) === -1 && column["maxLength"] !== -1) {
                const vTargetCol:any = null;//this.columns?this.columns.find(x=>x.name === column.name+"_ID"):null;
                column.distinct = {
                    columns: vTargetCol == null ? [column.name] : [column.name, vTargetCol.name],
                    column: vTargetCol == null ? column.name : vTargetCol.name
                };
                if (!column.filterDropdown) {
                    column.filterDropdown = "OFilterDropdown";
                }
            }
            if (column.type == "string" && !column.distinct && !column.filterDropdown) {
                column.filterDropdown = "OFilterDropdown";
            }
            if(this.columnExistsOptions.has(pColumnName)){
                column.existsOptions = this.columnExistsOptions.get(pColumnName);
            }
            if (this.columnDistinctOptions.has(pColumnName)) {
                const vOptions:any = this.columnDistinctOptions.get(pColumnName);
                
                if(column.existsOptions && vOptions){
                    if(vOptions.hasOwnProperty("displayColumn")){
                        column.existsOptions['displayColumn'] = vOptions["displayColumn"]
                    }
                    vOptions.existsOptions = column.existsOptions;
                }
                column.distinctOptions = this.columnDistinctOptions.get(pColumnName);

            }
            this.filterItems[pColumnName] = new Proxy(new FilterItem(column), {
                set: (obj, prop, value) => {
                    Reflect.set(obj, prop, value);
                    if (prop == "excluded") this.updateFilterString();
                    if (prop == "selectedValue") this.updateFilterString();
                    if (prop == "operator") this.updateFilterString();
                    return true;
                }
            });
            if(this.columnExistsOptions.has(pColumnName)){
                this.filterItems[pColumnName].setExistsOptions(this.columnExistsOptions.get(pColumnName));
            }
            if (this.distinctOptions) {
                this.filterItems[pColumnName].setDistinctOptions(this.distinctOptions);
            }
            if (this.columnDistinctOptions.has(pColumnName)) {
                this.filterItems[pColumnName].setDistinctOptions(this.columnDistinctOptions.get(pColumnName));

            }
        }


        return this.filterItems[pColumnName];
    }

    updateFilterString() {
        const filters = Object.values(this.filterItems).filter(x => (!x.excluded && x.selectedValue != null) || x.type == 'group')
            .map(item => item.type == 'group' ? item : item.item
            );
   


        this.filterObject = createInitialObject(null);
        
        this.filterObject.items[0]['items'] = filters;
        this._filterString = filterItemsToString(this.filterObject);
        this._prettyString = prettifyFilterItems(this.filterObject, this.columns);



    }


    setColumnDistinctOptions(pColumnName: string, pOptions: any) {
       
        
        
        this.columnDistinctOptions.set(pColumnName, this._extendOptionsWithTargetType(pOptions));
       

        if (this.filterItems.hasOwnProperty(pColumnName)) {
            this.filterItems[pColumnName].setDistinctOptions(pOptions);
        }else{
            this.getItem(pColumnName);//.setDistinctOptions(pOptions);
        }
        if(pOptions.targetColumn){

           
            const vType = this.columns.find(x=>x.name == pOptions.targetColumn);
            if(vType){
                this.getItem(pColumnName).distinctTargetColumnType = vType.type;
            }
            if (this.filterItems.hasOwnProperty(pOptions.targetColumn)) {
                const update = {
                    operator: this.filterItems[pOptions.targetColumn].operator,
                    selectedValue: this.filterItems[pOptions.targetColumn].value
                }
                this.filterItems[pOptions.targetColumn].resetItem();
                this.getItem(pColumnName).updateFromServer(update);

                
               
            }
        }
    }
    setDistinctOptions(pOptions: Object) {
        this.distinctOptions = pOptions;
        Object.keys(this.filterItems).forEach(col => {
            this.setColumnDistinctOptions(col, pOptions);
        })
        //update all options
    }

    setColumnExistsOptions(pColumn:string,pOptions:ExistsObject){
        this.columnExistsOptions.set(pColumn,this._extendOptionsWithTargetType(pOptions));
      //  this.getItem(pColumn)?.setExistsOptions(pOptions);
    }

    private _extendOptionsWithTargetType(pOptions:any){
        if(pOptions.targetColumn){
            const vField = this.columns.find(x=>x.name == pOptions.targetColumn);
           if(vField) pOptions.targetColumnType = vField.type;
        }

        return pOptions;
    }



    updateItems(pFilterItems: any,pUrlFilter:boolean = true) {
        if (pFilterItems && pFilterItems.items) {
            pFilterItems.items.forEach((item: { column: string; operator: any; value: any; }) => {
                
                if(item.type =="function"){
                    item = itemFromFunctionItem(item,this.filterItems);
                    item.expressionValue = item.value;
                }
                if(item.column && item.column.startsWith("_FULL_TEXT_")){
                    if(!this.activeSearchFncItem){
                        this._searchFncOptions = item;
                    }else{
                        this.activeSearchFncItem.setFromSavedString(item);
                    }
                }else if (item.column) {
                    if(pUrlFilter){
                        for (const [key, value] of this.columnDistinctOptions.entries()) {
                            if(value && value['targetColumn'] == item.column){
                                item.column = key;
                                item.useAlias = true;
                            }
                        }


                    }
                    if(item.value && checkIfColumn(item.value)){
                        const vColValue = this.columns.find(x=>x.name== item.value.replace("[",'').replace("]",''));
                        item.expressionValue = vColValue?"["+vColValue.caption+"]":null;
                    }
                    this.getItem(item.column).updateFromServer({
                        operator: item.operator,
                        selectedValue: item.value,
                        useAlias: item.useAlias,
                        expressionValue : item.expressionValue,
                        
                    });
                }else if(item && item.constructor == Array && item[0] == "fnc"){
                    if(!this.activeSearchFncItem){
                        this._searchFncOptions = item;
                    }else{
                        this.activeSearchFncItem.setFromUrl(item);
                    }
                   
                }


                this.updateItems(item);
            })
        }
    }

    updateItem(pFilter:any){
        this.getItem(pFilter.column)!.selectedValue = pFilter.value;
        if(pFilter.operator) this.getItem(pFilter.column)!.operator = pFilter.operator;
    
    }


    async applySavedFilter(pFilter: SavedFilter) {
        this.activeFilter = pFilter;
        await this.applyInitFilter(pFilter.FilterCriteria, true);
        this.setFieldFilters(Object.values(this.filterItems).filter(item => item.selectedValue).map((item: FilterItem) => item.column), true, true);
    }

    async applyInitFilter(pFilterString: string, pReload: boolean) {
        if (!pFilterString) { return; }
        if(pFilterString.toLowerCase().indexOf(' or ') > -1){
            this.resetFilterItems();
            this.setFilterString(pFilterString);

            this._filterString = pFilterString;
            this._prettyString = pFilterString;
            if (this._urlFilter) this._urlFilter.update([]);
            if(pReload) this.dataObject.load();
            return;
        }
        const filter = await filterStringToFilterItems(pFilterString);
        this.resetFilterItems();
        this.updateItems(filter);
        this.updateFilterString();

        if (pReload) { this.apply(); }
    }

    apply() {
        if (this.hasChanges) {

            this.setFilterString(this._filterString);
            //this.setFilterString(this._filterStringWithoutSearch);

           // this._setSearchFunctionProps();

            Object.values(this.filterItems).forEach(item => {
                if (item.selectedValue != null) {
                    item.setAsApplied();
                } else if (item.selectedValue === undefined) {
                    item.resetItem();
                }
            });

            this.activeFilterObject = this.filterObject;

            if (this._urlFilter) {
                this._urlFilter.update(this.activeItems);
            }
            return this.dataObject.load();
        }
        return Promise.resolve();
    }

    _getFilterWithoutSearchFnc(){

    }

    resetFilterItems() {
        Object.values(this.filterItems).forEach(item => {
            item.clear();
        });
        if(this._activeSearchFncItem){
            this._activeSearchFncItem.value = null;
            this._setSearchFunctionProps();
        }
    }
    getClone() {
        return utilsData.cloneObject(this.filterObject);
    }

    getActiveClone() {

        return utilsData.cloneObject(this.activeFilterObject);
    }

    setFullTextOnColumn(pColumn:string,pSearchFunction:string){
        this.getItem(pColumn)?.setFullText(pSearchFunction);
    }

    enableUrlFilter(pOptions?: {
        /** Legacy (CT) data source id to use instead of the current dataobject id */
        legacyId?: string
    }) {
        this._urlFilter = new UrlFilter(this._urlFilterKey, this.columns);
        this.urlFiltering = true;
        const ctFilter = getLegacyUrlFilter(pOptions?.legacyId ?? this.dataObject.id);
        const vFilter = this._urlFilter.getFilterItems();
        const vFilterString = vFilter && vFilter.mode?filterItemsToString(vFilter):null;
        if (ctFilter) {
            this.applyInitFilter(ctFilter, true).then(() => import('o365-utils')).then(({Url}) => {
                const legacyUrlKey = `filter[${pOptions?.legacyId ?? this.dataObject.id}]`;
                 Url.setParam(legacyUrlKey, undefined);
            });
        }else if(vFilterString && vFilterString.toLowerCase().indexOf(" or ") > -1){
            this.applyInitFilter(filterItemsToString(vFilter),false);
        }else if (vFilter && vFilter.mode){
             this.updateItems({
                items: vFilter.items[0].items
            },true);
            this.updateFilterString();
           // this.setFilterString(this._filterString);
        } else if(vFilter){

            this.updateItems({
                items: vFilter
            },true);
            this.setFilterString(this._filterString);
           // this.updateFilterString();
        }
        this.activeFilterObject = this.filterObject;
    }

    clear(pSkipReload: true): void
    clear(pSkipReload?: false): ReturnType<DataObject['load']>
    clear(pSkipReload?: boolean) {
        this.activeFilter = null;
        /**if has changes, just need to restore from previuos */
        this._filterString = null;
        this._prettyString = null;
        this.activeFilterObject = null;
        this.resetFilterItems();
        this.dataObject.recordSource.clearFilter();
        if (this.urlFiltering) {
            this._urlFilter?.reset();
        }

        if (this._previousFieldFilters) {
            this.setFieldFilters(this._previousFieldFilters.map(item => ({
                name: item.name!,
                distinct: item.distinct?.columns
            })), true);
            this._previousFieldFilters = null;
        }
        if (pSkipReload === true) { return; }
        return this.dataObject.load();
    }

    /** Apply persistent fitler from local storage */
    initializePersistentFilter() {
        if (this.urlFiltering && this.appliedFilterString) {
            // this._persistentFilterId = undefined;
            const initialFilter = this.getPersistentFilter();
            if (initialFilter) {
                this.persistentFilterEnabled = true;
            }
            return;
        }
        if (this.persistenFilterKey) {
            const initialFilter = this.getPersistentFilter();
            if (initialFilter) {
                this.persistentFilterEnabled = true;
                this.updateItems(initialFilter, false);
                this.apply();
            }
        }
    }

    /** Store applied filter object to local store if persistentFilterId is set */
    setPersistentFilter() {
        if (!this.persistenFilterKey) { return; }
        localStorageHelper.setItem(this.persistenFilterKey, JSON.stringify(this.filterObject ?? {}), { global: true });
    }

    /** Clear persistent filter from local storage */
    removePersistentFilter() {
        if (!this.persistenFilterKey) { return; }
        localStorageHelper.removeItem(this.persistenFilterKey, { global: true });
    }

    _setSearchFunctionProps(){

        if(this.activeSearchFncItem){
            if(this.activeSearchFncItem.isSet){
                this.dataObject.recordSource.searchFunction = this.activeSearchFncItem.fnc;
                this.activeSearchFncItem.update
                this.dataObject.recordSource.searchString = this.activeSearchFncItem.searchString;
            }else{
                this.dataObject.recordSource.searchFunction = "";
                this.dataObject.recordSource.searchString= "";
            }
           
          
        }
       
    }

    setSearchFunction(pColumn:string,pSearchFunction:string, pLabel:string){
        this.getItem(pColumn)?.setSearchFunction({
            searchFnc:pSearchFunction,
            label:pLabel
        });

        this._activeSearchFncItem = this.getItem(pColumn)?.searchFunction;
        if(this._searchFncOptions){
            this.activeSearchFncItem.setFromExternal(this._searchFncOptions);
        }
    }

    /** Get current stored persistent filter object from local storage */
    getPersistentFilter() {
        if (!this.persistenFilterKey) { return; }
        try {
            const filterObjectString = localStorageHelper.getItem(this.persistenFilterKey, { global: true});
            return JSON.parse(filterObjectString);
        } catch {
            return undefined;
        }
    }

    /** Local storage key for persisent filters */
    get persistenFilterKey() {
        return this.persistentFilterId
            ? `persistent-filter-${this.dataObject.appId}-${this.persistentFilterId}`
            : undefined;
    }




}

function checkIfColumn(value: string) {
    if (value === null || value === undefined) {
        return false;
    }
    if (typeof value === "string" && value.indexOf("[") === 0 && value.indexOf("]") === value.length - 1) {
        return true;
    } else {
        return false;
    }
}


let legacyFilters: null | Map<string, string> = null;
export function getLegacyUrlFilter(pID: string) {
    if (legacyFilters == null) {
        legacyFilters = new Map();
        const searchParamsString = window.location.search;
        if (!searchParamsString) { return undefined; }
        const searchParams = new URLSearchParams(searchParamsString);
        searchParams.forEach((value, key) => {
            if (!key.startsWith('filter[')) { return; }
            legacyFilters!.set(key, value);
        });
    }
    const filter = legacyFilters.get(`filter[${pID}]`);
    return filter;
}
